<template>
  <div class="container p-0 m-0">
    <div class="row full-heigth p-0 m-0">
      <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"></loading>
      <div class="card card-fullheight m-0 pb-2 w-100">
        <div class="card-body">
          <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
            aria-label="Close modal"><i class="fas fa-times"></i></button>
          <h5 class="box-title mb-1 pb-1"><small>Motivo de Avaliação - Id:</small>  {{ reason.id }}</h5>
          <hr>

          <!-- Informações -->
          <div class="row mt-2">

            <div class="col-md-6 px-0 pb-3 text-left">
              <small>Nome</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(reason.nome) }}
              </div>
            </div>

            <div class="col-md-6 px-0 pb-3 text-left">
              <small>Tipo</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(reason.tipo.nome) }}
              </div>
            </div>

            <div class="col-md-6 px-0 pb-3 text-left">
              <small>Peso Padrão</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(reason.peso_padrao) }}
                <small class="text-primary" v-if="reason.tipo.positivo">pontos positivos</small>
                <small class="text-danger" v-else>pontos negativos</small>
              </div>
            </div>

            <div class="col-md-6 px-0 pb-3 text-left">
              <div class="font-weight-bold pt-2">
                <small class="text-danger" v-if="reason.possui_valor">* precisa informar o valor envolvido</small>
                <small class="text-primary" v-else>* não precisa informar o valor</small>
              </div>
            </div>

            <div v-if="reason.descricao" class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3 text-left">
              <small>Descrição</small>
              <div class="font-weight-bold">
                {{ $util.formatEmpty(reason.descricao) }}
              </div>
            </div>

          </div>

          <hr>

          <!-- Botões abaixo -->
          <div class="row">
            <div class="col-12 align-center">
                <a class="btn btn-sm btn-outline-light m-1"
                  v-bind:href="$util.mountLink('ValuationReasonEdit', this.reason)">Editar</a>

                <button class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onClose()">
                  <span >Fechar</span>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'ValuationReasonDetailView',
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      subsLoaded: false
    }
  },
  beforeMount () {
  },
  mounted () {
  },
  methods: {
    onfocus () {
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    }
  },
  computed: {
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
